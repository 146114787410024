import { otherLocale } from "@config/languages";
import { IClosingPeriod } from "@shared/types/pickupPoints.type";
import { convertDayAndHoursIntoDate, isSameDate } from "@utils/date.utils";
import { formatShippingDate } from "@utils/format.utils";

export const formatStoreHours = ({ seconds, locale }: { seconds?: number; locale: string }) => {
	if (!seconds) return "";

	const hour = Math.floor(seconds / 3600);
	const minutes = Math.floor(seconds % 3600) / 60;
	const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

	if (locale === otherLocale) return `${hour}:${formattedMinutes}`;

	const period = hour >= 12 ? "pm" : "am";
	const formattedHour = hour % 12 || 12;

	return `${formattedHour}:${formattedMinutes} ${period}`;
};

export const findStartOfWeek = (date: string) => {
	const startOfWeek = new Date(date);
	startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());
	return startOfWeek;
};

export const findDaysClosed = ({
	closingPeriods,
	today,
}: {
	today: Date;
	closingPeriods?: IClosingPeriod[];
}) => {
	if (!closingPeriods?.length) return;

	const startOfWeekToday = findStartOfWeek(today.toDateString());

	const closedWeeks = closingPeriods.map(({ closed_from }) => {
		const from = new Date(closed_from * 1000);
		const startOfWeekFrom = findStartOfWeek(from.toDateString());
		const dayFrom = isSameDate(startOfWeekFrom, startOfWeekToday) ? from.getDay() : undefined;
		return { startOfWeekFrom, dayFrom };
	});

	const isWeekOfClosingPeriod = closedWeeks.some(({ startOfWeekFrom }) => {
		return isSameDate(startOfWeekToday, startOfWeekFrom);
	});

	if (!isWeekOfClosingPeriod) return;

	return closedWeeks.map(({ dayFrom }) => dayFrom);
};

export const formatPickupTime = ({
	earliestMilliseconds,
	nowMilliseconds,
	textInHours,
}: {
	earliestMilliseconds: number;
	nowMilliseconds: number;
	textInHours: string;
}) => {
	const difference = earliestMilliseconds - nowMilliseconds;
	const timeRemaining = Math.ceil(Math.max(0, difference / (1000 * 60 * 60)));
	return timeRemaining ? textInHours.replace("[[timeForFulfillment]]", `${timeRemaining}`) : "";
};

export const displayTimeOrDate = ({
	earliestDate,
	earliestTime,
	textInHours,
	textFrom,
	locale,
}: {
	earliestDate: string;
	locale: string;
	textInHours: string;
	textFrom?: string;
	earliestTime?: string;
}) => {
	if (!earliestDate) return "";
	const earliest = convertDayAndHoursIntoDate(earliestDate, earliestTime);
	const now = new Date();
	const from = textFrom ? `${textFrom} ` : "";

	return isSameDate(earliest, now) && earliestTime
		? formatPickupTime({
				earliestMilliseconds: earliest.getTime(),
				nowMilliseconds: now.getTime(),
				textInHours,
		  })
		: `${from}${formatShippingDate(earliest, locale)}`;
};
