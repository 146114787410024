import { Dispatch, SetStateAction } from "react";
import axios from "axios";
import {
	addProductToCart,
	setItemsCount,
	setQueue,
	setReady,
	TCartActions,
} from "@redux/cart/cart.reducer";
import { ICartUpdate } from "@shared/cart/cart.types";
import { IProduct, IProductWithAvailability } from "@shared/types/product.types";
import { t } from "@utils/translations.utils";
import { ICartLine } from "@shared/types/cart.types";
import { IProductRecommendationCards } from "@shared/recommendation/recommendation.types";
import { globalDataLayer } from "@shared/datalayer/DataLayer";
import { EDataLayerEventType, EDataLayerListType } from "@shared/datalayer/datalayer.types";
import {
	buildDataLayerCartDetails,
	buildDataLayerProductDetails,
	findCartLineById,
} from "@shared/datalayer/cart-datalayer/cart-datalayer";
import { ICartFulfillment } from "./FulfillmentOptions/fulfillment-options.types";
import { formatMonthDay, formatShippingDate } from "@utils/format.utils";
import { getCheckoutSuggestions } from "@shared/cart/cart.utils";
import {
	filterByAvailability,
	getRecommendationDataWithAvailability,
} from "@shared/recommendation/recommendation.utils";
import { buildCrossSellingProductImpressionsDataLayer } from "@shared/datalayer/cross-selling-product-datalayer/cross-selling-product-datalayer";
import { ICartInfos } from "@shared/scenarios/scenarios.server";
import { logCatchError } from "@utils/api.utils";
import { convertDayAndHoursIntoDate, isSameDate } from "@utils/date.utils";
import { formatPickupTime } from "@components/Checkout/Delivery/How/Parcels/Parcel/FulfillmentOptions/Pickup/PickupOption/PickupOption.utils";
import { IContainer, IPickupPoint, IUserDelivery } from "@shared/types/containers.types";

export interface IAddToCartFromCarousel
	extends Pick<IProductRecommendationCards, "requireRefresh" | "setCartLineAddedId"> {
	product: IProduct;
	locale: string;
	dispatch: Dispatch<TCartActions>;
}
interface IRefreshCarouselInfo {
	modelCodes: number[];
	locale: string;
}
interface IShouldUpdateCarouselAndDataLayer {
	cartLines: ICartLine[];
	cartLineAddedId: string;
	cartLinesLength: number;
	setCartLinesLength: Dispatch<SetStateAction<number>>;
	locale: string;
	setAvailableRecommendationData: Dispatch<SetStateAction<IProductWithAvailability[]>>;
	setCartLineAddedId: Dispatch<SetStateAction<string>>;
}
interface IFormatTimeForDelivery {
	locale: string;
	translation: string;
	earliestDeliveryDate?: Date;
}

interface IEarliestDates {
	pickup?: Date;
	delivery?: Date;
}

export const addToCartFromCarousel = async ({
	dispatch,
	product,
	locale,
	requireRefresh,
	setCartLineAddedId,
}: IAddToCartFromCarousel) => {
	if (!product || !requireRefresh) return;

	const firstArticle = product.articles?.[0];
	const articleId = firstArticle?.articleId;
	const quantity = 1;
	const modelId = product.modelId;
	const itemSku = firstArticle?.sku;

	dispatch(setQueue());
	try {
		const { data } = await axios.post<ICartUpdate>("/api/cart", {
			itemCode: articleId,
			lineType: "DELIVERY",
			modelCode: modelId,
			quantity,
			itemSku,
		});
		dispatch(
			addProductToCart({
				articleId,
				modelId,
				itemSku,
				quantity,
				price: product.price,
				name: product.name,
				brand: product.brand,
				image: product.images?.[0]?.url ?? "",
				color: product.description?.informationConcept?.color?.[0],
				size: firstArticle?.attribute?.attributeValue,
			})
		);
		requireRefresh();
		if (data) {
			setCartLineAddedId?.(data.cartLines?.[0]?.cartLineIde);
			dispatch(setItemsCount(data.itemsCount));
		}
	} catch (error) {
		console.error(t("add_to_cart_error_message", locale)?.replace("[[articleId]]", `${articleId}`));
	} finally {
		dispatch(setReady());
	}
};

export const dispatchAddToCartCarousel = (cartLines: ICartLine[], cartLineAddedId: string) => {
	if (!cartLines?.length) return;

	const cartLineAdded = findCartLineById(cartLines, cartLineAddedId);
	const cart = buildDataLayerCartDetails(cartLines);
	const cartUpdate = cartLineAdded && [buildDataLayerProductDetails(cartLineAdded)];
	globalDataLayer.customDataLayer.dispatchData({
		event: EDataLayerEventType.AddToCartCarousel,
		cartUpdate,
		cart,
	});
};

export const getCartFulfillment = async (
	cartInfos: ICartInfos,
	locale: string
): Promise<ICartFulfillment | undefined> => {
	try {
		const { data } = await axios.post("/api/scenario", { cartInfos, locale });
		return data;
	} catch (error) {
		logCatchError({ error });
		return;
	}
};

export const getFastestPickUp = (pickups: IPickupPoint[] = []): Date | undefined => {
	let earliestPickup: Date | undefined;
	for (const pickup of pickups) {
		const dateFrom = pickup.delivery?.date_from;
		if (!dateFrom) return;
		const pickupDate = convertDayAndHoursIntoDate(dateFrom, pickup.delivery.time_window?.time_from);
		if (!earliestPickup || pickupDate < earliestPickup) {
			earliestPickup = pickupDate;
		}
	}
	return earliestPickup;
};

export const getFastestDelivery = (deliveries: IUserDelivery[] = []): Date | undefined => {
	let earliestDelivery: Date | undefined;
	for (const userDelivery of deliveries) {
		const dateFrom = userDelivery.delivery_slots?.[0]?.date_from;
		if (!dateFrom) return;
		const userDeliveryDate = convertDayAndHoursIntoDate(dateFrom);
		if (!earliestDelivery || userDeliveryDate < earliestDelivery) {
			earliestDelivery = userDeliveryDate;
		}
	}
	return earliestDelivery;
};

export const getFastestDates = (
	locale: string,
	textHours: string,
	deliveryToday: string,
	scenarios: IContainer[]
) => {
	const earliestDates: IEarliestDates = {};
	const now = new Date();

	for (const container of scenarios) {
		if (container?.pickup) {
			earliestDates.pickup = getFastestPickUp(container.pickup);
		}
		if (container?.user_deliveries) {
			earliestDates.delivery = getFastestDelivery(container.user_deliveries);
		}
	}

	return {
		pickup:
			earliestDates.pickup && isSameDate(earliestDates.pickup, now)
				? formatPickupTime({
						earliestMilliseconds: earliestDates.pickup.getTime(),
						nowMilliseconds: now.getTime(),
						textInHours: textHours,
				  })
				: formatShippingDate(earliestDates.pickup, locale),
		delivery: formatTimeForDelivery({
			earliestDeliveryDate: earliestDates.delivery,
			translation: deliveryToday,
			locale,
		}),
	};
};

export const refreshCarouselInfo = async ({ modelCodes, locale }: IRefreshCarouselInfo) => {
	if (!modelCodes?.length) return;

	const crossSellingData = await getCheckoutSuggestions({
		modelId: modelCodes,
		locale,
	});
	const productsWithAvailability = await getRecommendationDataWithAvailability({
		crossSellingData,
	});

	if (!productsWithAvailability) return;

	return filterByAvailability(productsWithAvailability);
};

export const shouldUpdateCarouselAndDataLayer = async ({
	cartLineAddedId,
	cartLines,
	cartLinesLength,
	locale,
	setAvailableRecommendationData,
	setCartLinesLength,
	setCartLineAddedId,
}: IShouldUpdateCarouselAndDataLayer) => {
	if (!cartLines?.length) return;

	if (cartLineAddedId) {
		dispatchAddToCartCarousel(cartLines, cartLineAddedId);
		setCartLineAddedId("");
	}

	setCartLinesLength(cartLines.length);

	if (cartLinesLength !== cartLines.length) {
		const updatedCarouselInfo = await refreshCarouselInfo({
			modelCodes: cartLines.map((line) => line.modelCode),
			locale,
		});

		if (updatedCarouselInfo) {
			setAvailableRecommendationData(updatedCarouselInfo);
			globalDataLayer.customDataLayer.dispatchData(
				buildCrossSellingProductImpressionsDataLayer({
					crossSellingList: [
						{
							dataLayerList: EDataLayerListType.CartReco,
							crossSellingProductsWithAvailability: updatedCarouselInfo,
						},
					],
					isEvent: true,
				})
			);
		}
	}
};

export const formatTimeForDelivery = ({
	earliestDeliveryDate,
	translation,
	locale,
}: IFormatTimeForDelivery) => {
	if (!earliestDeliveryDate) return;
	const today = new Date();

	return isSameDate(today, earliestDeliveryDate)
		? translation
		: formatMonthDay(earliestDeliveryDate, locale);
};
