import useGraphQL from "@hooks/useGraphql";
import { SwiperSlide } from "swiper/react";
import {
	IProductLabelCollection,
	IProductLabelsVariables,
	labelsByProductQuery,
} from "@components/ProductInfo/ProductLabels/product-labels.query";
import { EProductLabelSection } from "@components/ContentfulComponents/ComponentProductLabel/type";
import { IProductWithAvailability } from "@shared/types/product.types";
import { EGridSize } from "@components/ContentfulComponents/ContentPagesContentBlock/type";
import { IProductRecommendationCards } from "@shared/recommendation/recommendation.types";
import { TGenericTranslation } from "@shared/types/translations.types";
import CarouselLayout from "@components/Carousels/CarouselLayout/CarouselLayout.component";
import ProductRecommendationCard from "./ProductRecommendationCard/ProductRecommendationCard.component";

import styles from "./carousel-product-recommendation.module.scss";

export interface ICarouselProductRecommendationProps extends IProductRecommendationCards {
	crossSellingData: IProductWithAvailability[];
	gridSize: EGridSize;
	titleTranslation: TGenericTranslation;
	dataLayerListName?: string;
	wrapperClassname?: string;
	layoutWrapperClassname?: string;
}

const CarouselProductRecommendation = ({
	crossSellingData,
	gridSize,
	recommendationType,
	titleTranslation,
	btnTranslation,
	requireRefresh,
	dataLayerListName,
	setCartLineAddedId,
	wrapperClassname,
	layoutWrapperClassname = "",
}: ICarouselProductRecommendationProps) => {
	const { data: labelsByProductData } = useGraphQL<
		IProductLabelCollection,
		IProductLabelsVariables
	>(labelsByProductQuery, {
		variables: {
			section: EProductLabelSection.Price,
		},
	});

	if (!crossSellingData?.length) return null;

	const prevArrow = `prev-${recommendationType}`;
	const nextArrow = `next-${recommendationType}`;
	const title = (titleTranslation || "") as string;

	return (
		<div
			className={`${styles.layoutWrapper} ${layoutWrapperClassname}`}
			data-testid="CarouselProductRecommendation_layout-wrapper"
		>
			<CarouselLayout
				gridSize={gridSize}
				nextArrow={nextArrow}
				prevArrow={prevArrow}
				slidesPerView={{
					mobile: 2.2,
					tablet: 3.2,
					desktop: 4.2,
				}}
				title={title}
				wrapperClassname={wrapperClassname}
			>
				{crossSellingData.map((item, index) => {
					if (!item) return null;
					const datalayerProductInfo = { ...item, index, list: dataLayerListName };
					return (
						<SwiperSlide key={item.product?.modelId}>
							<ProductRecommendationCard
								productData={item.product}
								recommendationType={recommendationType}
								btnTranslation={btnTranslation}
								requireRefresh={requireRefresh}
								datalayerInfo={datalayerProductInfo}
								setCartLineAddedId={setCartLineAddedId}
								labels={labelsByProductData?.productLabelCollection?.items}
							/>
						</SwiperSlide>
					);
				})}
			</CarouselLayout>
		</div>
	);
};

export default CarouselProductRecommendation;
