// eslint-disable-next-line @typescript-eslint/no-explicit-any
type logMessage = string | Record<string, any>;

export enum ELogType {
	Info = "info",
	Error = "Error",
}

export const logServerInfo = (message: logMessage) => logServer(message);
export const logServerError = (message: logMessage) => logServer(message, ELogType.Error);

const logServer = (message: logMessage, type?: ELogType) => {
	return type === ELogType.Error ? console.error(message) : console.info(message);
};

export default logServer;
