import { useDispatch } from "react-redux";
import useLocale from "@hooks/useLocale";
import { useAbsolute } from "@hooks/useAbsolute";
import { IProduct, IProductWithAvailabilityAndIndex } from "@shared/types/product.types";
import { getPropsForImages } from "@components/CustomImage/custom-images.utils";
import { addToCartFromCarousel } from "@components/Cart/FilledCart/filled-cart.utils";
import { filterLabelsByModelId } from "@shared/contentful/contentful.utils";
import { EImageSlot } from "@components/CustomImage/custom-images.types";
import {
	ERecommendationType,
	IProductRecommendationCards,
} from "@shared/recommendation/recommendation.types";
import { EButtonSize, EButtonType } from "@components/Button/types";
import { IProductLabelCflData } from "@components/ContentfulComponents/ComponentProductLabel/type";
import CustomImage from "@components/CustomImage/CustomImage.component";
import ProductLabels from "@components/ProductInfo/ProductLabels/ProductLabels.component";
import CarouselProductNameBrand from "@components/Carousels/CarouselProductNameBrand/CarouselProductNameBrand.component";
import ProductCardReviews from "@components/ProductCard/ProductCardReviews.component";
import CarouselProductPrice from "@components/Carousels/CarouselProductPrice/CarouselProductPrice.component";
import Button from "@components/Button/Button.component";
import { globalDataLayer } from "@shared/datalayer/DataLayer";
import { buildCrossSellingProductClickDataLayer } from "@shared/datalayer/cross-selling-product-datalayer/cross-selling-product-datalayer";

import styles from "@components/Carousels/CarouselProductRecommendation/ProductRecommendationCard/product-recommendation-card.module.scss";

export interface IProductRecommendationCard extends IProductRecommendationCards {
	productData: IProduct;
	datalayerInfo: IProductWithAvailabilityAndIndex;
	labels?: IProductLabelCflData[];
}

const ProductRecommendationCard = ({
	productData,
	recommendationType,
	btnTranslation,
	requireRefresh,
	datalayerInfo,
	setCartLineAddedId,
	labels,
}: IProductRecommendationCard) => {
	const { absoluteUrl } = useAbsolute(
		`/p/${productData?.modelId}/${productData?.description?.slug}`
	);

	const dispatch = useDispatch();
	const { locale } = useLocale();

	if (!productData) return null;

	const { modelId, description, images, priceForFront, brand, review } = productData;

	if (!modelId || !description?.slug || !images?.length) return null;

	const filteredLabels = filterLabelsByModelId({ labels, modelId });

	const mobileProps = getPropsForImages({
		image: { ...images[0], description: description?.productName },
	});
	const desktopProps = {
		...mobileProps,
		slot: EImageSlot.Third,
	};
	const ctaTranslation = (btnTranslation ?? "") as string;

	const handleClick = () => {
		const productClicks = buildCrossSellingProductClickDataLayer(datalayerInfo);
		if (productClicks) {
			globalDataLayer.customDataLayer.dispatchData(productClicks);
		}
	};

	return (
		<div className={styles.productCardWrapper}>
			<a
				href={absoluteUrl}
				className={styles.productInfoWrapper}
				onClick={handleClick}
				data-testid="ProductRecommendationCard_wrapper"
			>
				<CustomImage
					mobile={mobileProps}
					desktop={desktopProps}
					isFromContentful={false}
					hasMixBlend
				/>
				<ProductLabels
					discountPercentage={priceForFront?.discountPercentage}
					labels={filteredLabels}
				/>
				<CarouselProductNameBrand productName={description?.productName} brand={brand} />
				<ProductCardReviews
					ratingAverage={review?.averageRating}
					ratingCount={review?.count}
					wrapperClassName={styles.productReviewsWrapper}
				/>
				<CarouselProductPrice priceForFront={priceForFront} />
			</a>
			{recommendationType === ERecommendationType.OftenBoughtWith && (
				<Button
					buttonSize={EButtonSize.Sm}
					as="button"
					buttonType={EButtonType.Primary}
					className={styles.btn}
					data-testid="ProductRecommendationCard_btn"
					onClick={() => {
						addToCartFromCarousel({
							dispatch,
							locale,
							product: productData,
							requireRefresh,
							setCartLineAddedId,
						});
						handleClick();
					}}
				>
					{ctaTranslation}
				</Button>
			)}
		</div>
	);
};

export default ProductRecommendationCard;
