import { Dispatch, SetStateAction } from "react";
import { TGenericTranslation } from "@shared/types/translations.types";
import { IServerRequest } from "@shared/types/request.types";
import { IProduct } from "@shared/types/product.types";

export interface IMostPopularProductData {
	but_margin: number;
	but_name: string | null;
	but_num: number | null;
	but_turnover: number;
	click_dsm_code: number | null;
	cnt_code: string;
	cnt_margin: number;
	cnt_qty: number;
	cnt_turnover: number;
	dsm_code: string;
	eco_design: number;
	id: number;
	is_female: number | null;
	is_junior: number | null;
	is_male: number | null;
	mdl_num: string;
	number_clicks: number;
	popularity_idx: number;
	popularity_idx_avg: number | null;
	product_nature_id: string;
	provinces_list: string[];
	qty_available_bu: number;
	qty_available_wh: number;
	sku_stock_percentage: number | null;
	sport_ids_list: number[];
	sportgroup_id: string;
	unv_num: string;
}

export enum ERecommendationType {
	AlsoViewed = "also-viewed",
	OftenBoughtWith = "often-bought-with",
	MostPopular = "most-popular",
	BetterTogether = "better-together",
}

export interface IProductRecommendationCards {
	recommendationType?: ERecommendationType;
	btnTranslation?: TGenericTranslation;
	requireRefresh?: () => void;
	setCartLineAddedId?: Dispatch<SetStateAction<string>>;
}

export interface IGetProductRecommendation {
	modelId: number | number[];
	path: string;
	params?: Record<string, string>;
}

export interface IGetRecommendedProductsInfo
	extends Pick<IGetProductRecommendation, "modelId">,
		IServerRequest {
	crossClick?: boolean;
}

export interface IGetRecommendedDataWithAvailability extends IServerRequest {
	crossSellingData?: IProduct[];
}

export interface IGetModelIdListInfo extends IServerRequest {
	productList: string[];
}
