/* eslint-disable @typescript-eslint/no-explicit-any */
import { NextApiResponse } from "next";
import { logServerError, logServerInfo } from "./logServer";
import { defaultLocale, locales } from "@config/languages";

interface IManageError<T = Record<string, unknown>> {
	res: NextApiResponse;
	statusError?: number[];
	error: any;
	data?: T;
}

export const logCatchError = ({
	error,
	statusError = [],
}: Pick<IManageError, "error" | "statusError">): number => {
	const statusCode = error?.response?.status || error?.status || 500;

	if (statusError.includes(statusCode)) {
		logServerInfo(error);
	} else {
		logServerError(error);
	}

	return statusCode;
};

export const manageCatch = <T>({ error, statusError = [], res, data }: IManageError<T>) => {
	const statusCode = logCatchError({ error, statusError });
	res.status(statusCode).json({
		error: error.message || error,
		...(data ?? {}),
	});
};

export const checkLocale = (locale?: string | string[]) => {
	if (!locale || Array.isArray(locale) || !locales.includes(locale)) return defaultLocale;
	return locale;
};

export const isEveryPropDefined = <T>(props: Record<string, string | T>) => {
	return Object.values(props).every((prop) => prop);
};
